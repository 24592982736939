import React from "react";
import styled from 'styled-components';
//STYLES
import { Container } from '../utils/utils';
//COMPONENTS
import SEO from '../components/seo/SEO';
import Layout from '../layout/layoutBW';

const Wyp = styled.div`
  height: 50vh;
`;

const Page1 = () => {

    return <div>
        <SEO
            title="Technologie"
            slug='/technologie' />
        <Layout>
            <Container>
                <Wyp />
            </Container>
        </Layout>
    </div>
}
export default Page1